define(['app', 'elementViewed'], function(app, elementViewed) {


  var papDescription = function() {
    var component = {};
    component.elementViewed = elementViewed;

    var _config = {
      selectors: {
        papDescriptionCTA: '.papDescription_cta'
      },
      classes: {
      }
    };

    var _init = function(element) {
      component.element = element;
      component.papDescriptionCTA = component.element.querySelector(component.config.selectors.papDescriptionCTA);
      component.bind();
      return component;
    };

    var _bind = function() {

      component.papDescriptionCTA.addEventListener('click', component.papDescriptionCTAClicked);

    };

    var _papDescriptionCTAClicked = function() {
      app.publish('tracking/record', 'Pap | papDescriptionCTA', 'Clicked', 'papDescription component');
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.papDescriptionCTAClicked = _papDescriptionCTAClicked;
    return component;
  };

  return papDescription;
});
